import './App.css';
import {Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import Conferenza from "./pages/Conferenza";
import {VistaApprovazione} from "./pages/VistaApprovazione";
import VistaPresentatore from "./pages/VistaPresentatore";
import Prompter from "./pages/Prompter";
import {toolpressTheme} from "./theme/toolpressTheme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import React from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import {Login} from "./pages/Login";
import {Clicker} from "./pages/Clicker";
import Reports from "./pages/Reports";
import DomandaAttivaVisual from "./pages/DomandaAttivaVisual";

function App() {
    console.log("Developed by\n" +
        "\n" +
        "______ _           _                \n" +
        "| ___ \\ |         | |               \n" +
        "| |_/ / | ___  ___| |__    ___ ___  \n" +
        "|  __/| |/ _ \\/ __| '_ \\  / __/ _ \\ \n" +
        "| |   | |  __/\\__ \\ | | || (_| (_) |\n" +
        "\\_|   |_|\\___||___/_| |_(_)___\\___/ \n" +
        "                                    \n" +
        "                                    \n")


    return (
        <ThemeProvider theme={toolpressTheme}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route element={<ProtectedRoute/>}>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/conferenza/:id' element={<Conferenza/>}/>
                    </Route>
                    <Route path='/conferenza/:id/reports' element={<Reports/>}/>
                    <Route path='/approvazione/:id' element={<VistaApprovazione azienda={true}/>}/>
                    <Route path='/presentatore/:id' element={<VistaPresentatore tablet={true}/>}/>
                    <Route path='/prompter/:id' element={<Prompter/>}/>
                    <Route path='/domanda-attiva/:id' element={<DomandaAttivaVisual />}/>
                    {/*<Route path='/domanda-attiva/:id' element={<DomandaAttiva />}/>*/}
                    <Route path='/clicker/:id' element={<Clicker />}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
