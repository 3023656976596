import React, {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import TabellaDomande from "../components/TabellaDomande";
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Stack,
    Tab,
    Typography
} from "@mui/material";
import {
    Add,
    Business,
    Delete,
    Edit,
    FileDownload,
    Gavel, MicExternalOn,
    QuestionAnswer,
    Settings,
    TextFields,
    TouchApp,
    Tv,
    Visibility
} from "@mui/icons-material";
import firebase from "firebase/compat/app";
import FormAddDomanda from "../components/FormAddDomanda";
import Header from "../components/Header";
import FormConfiguraPrompter from "../components/FormConfiguraPrompter";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {TabellaRifiutate} from "../components/TabellaRifiutate";
import FormEditConferenza from "../components/FormEditConferenza";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";
import {ButtonLoader} from "../components/shared/ButtonLoader";

export default function Conferenza({user}) {
    const navigate = useNavigate()
    const {id} = useParams()
    const {t} = useTranslation()
    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione','asc'), {})
    const [daApprovare, daApprovareLoading, daApprovareError] = useCollection(firestore.collection('tool-press/' + id + '/daApprovare').orderBy('timestamp','asc'), {})
    const [openAdd, setOpenAdd] = useState(false)
    const [openPromp, setOpenPromp] = useState(false)
    const [value, setValue] = React.useState('1');
    const [openRifiutate, setOpenRifiutate] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [wait, setWait] = useState(false)
    const [updating, setUpdating] = useState('')

    const openView = Boolean(anchorEl);
    const idPop = openView ? 'simple-popover' : undefined;

    const handleSetWait = (bool) => {
        setWait(bool)
    }

    const handleSetOpenRifiutate = () => {
        setOpenRifiutate(!openRifiutate)
    }

    const handleGoToReportPage = () => {
        navigate(`/conferenza/${id}/reports`)
    }

    const handleSetEditOpen = () => {
        setEditOpen(!editOpen)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const updateDomandaAttuale = async (amount) => {
        setUpdating(amount > 0 ? 'next' : 'prev')
        await axios.put(`${API_URL}/conference/${id}/updateCurrentQuestion`, {amount: amount})
        setUpdating('')
    }

    const handleSetOpenAdd = () => {
        setOpenAdd(!openAdd)
    }

    const handleSetOpenPromp = () => {
        setOpenPromp(!openPromp)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Header user={user}/>
            <Dialog open={wait}>
                <CircularProgress/>
            </Dialog>
            {(!loading && !daApprovareLoading && !domandeLoading) && <div>
                <FormEditConferenza open={editOpen} conferenza={dettagliConferenza} handleSetEditOpen={handleSetEditOpen}/>
                <Box sx={{background:'linear-gradient(90deg, rgba(10,2,36,1) 0%, rgb(15, 3, 54) 100%)', position: {xs:'inherit', md:'sticky'}, top:0, zIndex:'99'}}>
                    <Stack direction={{xs:'column', md:"row"}} justifyContent={"space-between"} sx={{px:4, pt:2}}>
                        <Stack direction={{xs:'column', md:"row"}} spacing={{xs:0 , md:4}} sx={{pb:{xs: 2, md:0}}}>
                            <Typography variant={"h4"}>
                                {t('conference.title')} <span style={{fontWeight:'bold'}}>{dettagliConferenza.data().nome}</span>
                            </Typography>
                            <Box>
                                <IconButton aria-label="edit" onClick={handleSetEditOpen}>
                                    <Edit/>
                                </IconButton>
                            </Box>
                            <Box>
                                <IconButton aria-label="report" onClick={handleGoToReportPage}>
                                    <FileDownload/>
                                </IconButton>
                            </Box>
                            <Box>
                                <Button startIcon={<Visibility/>} variant={"outlined"} onClick={handleClick}>
                                    {t('conference.views')}
                                </Button>
                            </Box>
                            <Popover
                                id={idPop}
                                open={openView}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'bottom',
                                }}
                            >
                                <MenuList>
                                    <MenuItem component={'a'} href={'https://form.toolpress.app/form/'+id} target={'_blank'} >
                                        <ListItemIcon>
                                            <QuestionAnswer/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.form')}</ListItemText>
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={Link} to={'/prompter/'+id}>
                                        <ListItemIcon>
                                            <Tv/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.prompterView')}</ListItemText>
                                    </MenuItem>
                                    <MenuItem component={Link} to={'/approvazione/'+id}>
                                        <ListItemIcon>
                                            <Gavel/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.agencyView')}</ListItemText>
                                    </MenuItem>
                                    <MenuItem component={Link} to={'/presentatore/'+id}>
                                        <ListItemIcon>
                                            <MicExternalOn/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.modView')}</ListItemText>
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={Link} to={'/clicker/'+id} target={'_blank'}>
                                        <ListItemIcon>
                                            <TouchApp/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.clicker')}</ListItemText>
                                    </MenuItem>
                                    <MenuItem component={Link} to={'/domanda-attiva/'+id} target={'_blank'}>
                                        <ListItemIcon>
                                            <TextFields/>
                                        </ListItemIcon>
                                        <ListItemText>{t('conference.currentQuestion')}</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                        </Stack>
                        <Typography>{t('conference.pastQuestions')} {dettagliConferenza.data().domandaAttuale}</Typography>
                    </Stack>
                    <Stack sx={{px: 4, py: 2}} direction={{xs:"column", md: "row"}} justifyContent={"space-between"}>
                        <Stack direction={{xs:"column", md: "row"}} spacing={4} sx={{pb: {xs:2 , md:0}}}>
                            <Button variant={"contained"} startIcon={<Add/>} onClick={handleSetOpenAdd}>
                                {t('conference.addQuestion')}
                            </Button>
                            <Box sx={{position:'relative'}}>
                                {updating === 'prev' && <ButtonLoader/>}
                                <Button variant={'outlined'} disabled={dettagliConferenza.data().domandaAttuale === 0 || updating !== ''}
                                        onClick={() => updateDomandaAttuale(-1)} color={"error"}>{t('conference.previous')}</Button>
                            </Box>
                            <Box sx={{position:'relative'}}>
                                {updating === 'next' && <ButtonLoader/>}
                                <Button disabled={domandeConferenza.docs.length < dettagliConferenza.data().domandaAttuale +1 || updating !== '' } onClick={() => updateDomandaAttuale(1)} variant={"contained"}
                                        color={"success"}>{t('conference.next')}</Button>
                            </Box>
                        </Stack>
                        <FormAddDomanda dettagliConferenza={dettagliConferenza} open={openAdd} handleSetOpenAdd={handleSetOpenAdd} colonne={dettagliConferenza.data().colonne} approvazione={dettagliConferenza.data().approvazione}/>
                        <TabellaRifiutate handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} id={id} openRifiutate={openRifiutate} handleSetOpenRifiutate={handleSetOpenRifiutate}/>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Button variant={"contained"} color={"warning"} onClick={handleSetOpenPromp} startIcon={<Settings/>}>
                                {t('prompter')}
                            </Button>
                            {dettagliConferenza.data().approvazione && <Button variant={"outlined"} color={'warning'} startIcon={<Delete/>}
                                     onClick={handleSetOpenRifiutate}>{t('conference.refused')}</Button>}
                        </Stack>
                    </Stack>
                </Box>
                {dettagliConferenza.data().approvazione ?
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="" centered variant={"fullWidth"}>
                                    <Tab label={<div>
                                        {t('conference.toApprove')} <Badge sx={{pl: 2}} badgeContent={daApprovare.docs.length} color="primary"/>
                                        </div>
                                        } value="1" />
                                    <Tab label={<div>
                                        {t('conference.approved')} <Badge sx={{pl: 2}}
                                                         badgeContent={domandeConferenza ? (domandeConferenza.docs.length - dettagliConferenza.data().domandaAttuale) : 0}
                                                         color="primary"/>
                                    </div>} value="2"/>
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {loading && <CircularProgress/>}
                                {(!loading && !daApprovareLoading) &&
                                    <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} domandeConferenza={daApprovare} tipo={'daApprovare'}/>
                                }
                            </TabPanel>
                            <TabPanel value="2">
                                {loading && <CircularProgress/>}
                                {(!loading && !domandeLoading) &&
                                    <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} domandeConferenza={domandeConferenza} tipo={'approvate'}/>
                                }
                            </TabPanel>
                        </TabContext>
                    </Box>
                    :
                    <div>
                        {loading && <CircularProgress/>}
                        {(!loading && !domandeLoading) &&
                            <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} domandeConferenza={domandeConferenza}/>
                        }
                    </div>
                }
                <FormConfiguraPrompter handleSetOpenPromp={handleSetOpenPromp} openPromp={openPromp} dettagliConferenza={dettagliConferenza} domandeConferenza={domandeConferenza}/>
            </div>}
        </div>
    )
}
