import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {green, red, yellow} from "@mui/material/colors";
import {
    Box,
    capitalize,
    Card,
    Collapse,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import DeleteQuestion from "./DeleteQuestion";
import EditQuestion from "./EditQuestion";
import {useEffect, useState} from "react";
import {ArrowBack, ArrowDownward, ArrowUpward, Check, Clear, Computer, DragIndicator} from "@mui/icons-material";
import firebase from "firebase/compat/app";
import {Comandi} from "./TabellaDomanda/Comandi";
import remote from '../assets/remote.png'
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";


export default function TabellaDomande({dettagliConferenza, azienda, tablet, domandeConferenza, tipo, handleSetWait}) {
    const {id} = useParams()
    const {t} = useTranslation()
    const [pins, setPins] = useState([])
    const [font, setFont] = useState(null)
    const [domande, setDomande] = useState([])
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    let lastPin = 0

    useEffect(() => {
        createPins()
        computeFont()
    }, [dettagliConferenza, domandeConferenza])

    useEffect(() => {
        let dom = []
        if (domandeConferenza) {
            domandeConferenza.docs.forEach((doc, i) => {
                let p = {}
                dettagliConferenza.data().colonne.forEach(c => p[c] = doc.data()[c])
                let merged = {...p, ...createData(doc.data().posizione, doc.id, doc.data().domanda, doc.data().remoto, doc.data().commento ? doc.data().commento : '', doc.data().numero)}
                dom.push(merged)
            })
        }
        if (!tipo || tipo === 'approvate') {
            dom.splice(0, dettagliConferenza.data().domandaAttuale)
        }
        setDomande(dom)
    }, [dettagliConferenza, domandeConferenza])

    function createPins() {
        let possiblePin = []
        for (let i = 1; i <= dettagliConferenza.data().pin; i++) {
            possiblePin.push(i)
        }
        let assignPin = Array(50).fill(possiblePin).flat()
        setPins(assignPin)
    }

    function computeFont() {
        if (azienda) {
            setFont(dettagliConferenza.data().fontAzienda / 16 + 'rem')
        } else if (tablet)
            setFont(dettagliConferenza.data().fontTablet / 16 + 'rem')
        else {
            setFont(dettagliConferenza.data().fontTabella / 16 + 'rem')
        }
    }

    function createData(posizione, id, domanda, remoto, commento, numero) {
        return {posizione, id, domanda, remoto, commento, numero};
    }

    const handleDragEnd = async (e) => {
        handleSetWait(true)
        if (azienda) {
            if (!e.destination) {
                handleSetWait(false)
                return
            }
            if (e.destination.index >= dettagliConferenza.data().righeAgenzia) {
                if (!e.destination) return;
                let tempData = domande;
                let [source_data] = tempData.splice(e.source.index, 1);
                tempData.splice(e.destination.index, 0, source_data);
                await updatePosition(tempData)
            }
        } else {
            if (!e.destination) {
                handleSetWait(false)
                return
            };
            let tempData = domande;
            let [source_data] = tempData.splice(e.source.index, 1);
            tempData.splice(e.destination.index, 0, source_data);
            await updatePosition(tempData)
        }
        handleSetWait(false)
    };

    async function updatePosition(dati) {
        let temp = dati ? dati : domande
        await axios.put(`${API_URL}/conference/${id}/reorder`, {domande: temp})
        handleSetWait(false)
    }

    const updatePin = async (amount) => {
        await axios.put(`${API_URL}/conference/${id}/update-pin`, {amount: amount})
    }

    const renderPin = (index, domanda) => {
        let pin = ''
        let pos = lastPin

        if (!dettagliConferenza.data().domandePin) {
            if (domanda !== '' && domanda !== undefined) {
                pin = '-'
            } else {
                pin = pins[parseInt(pos) + dettagliConferenza.data().offsetPin]
                lastPin++
            }
        } else {
            pin = pins[parseInt(pos) + dettagliConferenza.data().offsetPin]
            lastPin++
        }
        return pin
    }

    const approvaDomanda = async (domanda, tipo) => {
        handleSetWait(true)
        await axios.put(`${API_URL}/question/${id}/${domanda.id}/approve`, {tipo: tipo})
        handleSetWait(false)
    }

    const rifiutaDomanda = async (domanda, tipo) => {
        handleSetWait(true)
        await axios.put(`${API_URL}/question/${id}/${domanda.id}/refuse`, {tipo: tipo})
        handleSetWait(false)
    }


    const spostaDaApprovare = async (domanda, tipo) => {
        handleSetWait(true)
        await axios.put(`${API_URL}/question/${id}/${domanda.id}/unRefuse`, {tipo: tipo})
        handleSetWait(false)
    }

    return (
        <TableContainer sx={{px: 1}}>
            {(domandeConferenza) &&
                <DragDropContext onDragEnd={handleDragEnd} >
                    <Box sx={{maxWidth: '100%', width: '100%', display:{xs:'none', md:'block'}}} aria-label="simple table">
                        <Grid container style={{fontWeight: '600', width: '100%'}} spacing={2}>
                            <Grid item xs={azienda ? 1.5 : 1} md={1.5}>
                                <Grid container>
                                    <Grid item xs={4}/>
                                    {!tablet && <Grid item xs={3} align="left"
                                                      style={{fontSize: font, fontWeight: '600'}}>N°</Grid>}
                                    {(!azienda && !tablet && dettagliConferenza.data().pin > 0 && (!tipo || tipo === 'approvate')) &&
                                        <Grid item xs={3} align="left">
                                            <Stack style={{fontSize: font, fontWeight: '600'}}
                                                   direction={"row"} justifyContent={"center"}>
                                                <ArrowDownward onClick={() => updatePin(-1)}
                                                               fontSize={"small"}/> {t('tabellaDomande.pin')}
                                                <ArrowUpward
                                                    onClick={() => updatePin(+1)} fontSize={"small"}/></Stack>
                                        </Grid>}
                                </Grid>
                            </Grid>

                            {
                                dettagliConferenza.data().colonne.map((c, i) => {
                                    return <Grid item xs={2.5} md={2} key={i}
                                                 style={{fontSize: font, fontWeight: '600'}}>{capitalize(c)}</Grid>
                                })
                            }
                            {dettagliConferenza.data().haDomande &&
                                <Grid item xs align={"left"} style={{fontSize: font, fontWeight: '600'}}>
                                    {t('question')}
                                </Grid>
                            }
                            {((!azienda || dettagliConferenza.data().approvazione) && !tablet) &&
                                <Grid item xs={2}/>}
                        </Grid>
                    </Box>
                    <Droppable droppableId={'droppable-1'} isD>
                        {(provided, snapshot) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {domande?.map((row, i) => (
                                    <Draggable
                                        key={row.id}
                                        draggableId={row.id}
                                        index={i}
                                        isDragDisabled={!sm || !(((!azienda || (azienda && i >= dettagliConferenza.data().righeAgenzia)) && !tablet && (!tipo || tipo === 'approvate')))}
                                    >
                                        {(provider) => (
                                            <Card variant={'question'} {...provider.draggableProps}
                                                  ref={provider.innerRef}>
                                                <Box {...provider.dragHandleProps} sx={{p:{xs:2, md:0}}}>
                                                    <Grid container
                                                          alignItems={"start"}
                                                          key={row.n}
                                                          sx={{
                                                              color: (tablet || tipo === '' || tipo === 'approvate' || !dettagliConferenza.data().approvazione) ? (i === 0 ? red[400] : i === 1 ? green[400] : '#fff') : '#fff'
                                                          }}
                                                          spacing={2}
                                                    >
                                                        <Grid sx={{display:{xs:'none' , sm:'block'}}} item xs={azienda ? 1.5 : 1} md={1.5}>
                                                            <Grid container>
                                                                {((!azienda || (azienda && i >= dettagliConferenza.data().righeAgenzia)) && !tablet && (!tipo || tipo === 'approvate')) ?
                                                                    <Grid item
                                                                          xs={azienda ? 6 : 4}
                                                                          align={"center"}
                                                                          sx={{color: 'inherit'}}
                                                                          style={{fontSize: font}}
                                                                    >
                                                                        <DragIndicator/>
                                                                    </Grid> : <Grid item xs={4}/>}
                                                                {/*<Grid item xs={azienda ? 6 : 2} textAlign={"center"}>
                                                                {row.remoto && <img src={remote} style={{
                                                                    width: '100%',
                                                                    maxWidth: '2rem'
                                                                }}/>}
                                                            </Grid>*/}
                                                                {!tablet && <Grid item xs={3} align={"left"}
                                                                                  sx={{
                                                                                      color: 'inherit',
                                                                                      overflow: 'visible'
                                                                                  }}
                                                                                  style={{fontSize: font}}>
                                                                    {row.numero}
                                                                </Grid>}
                                                                {(!azienda && !tablet && dettagliConferenza.data().pin > 0 && (!tipo || tipo === 'approvate')) &&
                                                                    <Grid item xs={3} textAlign="center"
                                                                          sx={{color: 'inherit'}}
                                                                          style={{fontSize: font}}>
                                                                        {renderPin(i, row.domanda)}
                                                                    </Grid>}
                                                            </Grid>
                                                        </Grid>

                                                        {
                                                            dettagliConferenza.data().colonne.map((c, index) =>
                                                                <Grid key={index} item xs={12} sm={2.5} md={2}
                                                                      sx={{color: 'inherit', overflow: 'visible'}}
                                                                      style={{fontSize: font}}>{row[c]}</Grid>
                                                            )
                                                        }
                                                        {dettagliConferenza.data().haDomande &&
                                                            <Grid item xs align={"left"}
                                                                  sx={{color: 'inherit', overflow: 'visible'}}
                                                                  style={{fontSize: font}}>
                                                                {row.domanda}
                                                                <Collapse in={row.commento !== ''}>
                                                                    <Box sx={{py: 1}}>
                                                                        <Typography variant={"body1"}
                                                                                    fontStyle={"italic"}
                                                                                    color={'#fde451'}>
                                                                            {t('tabellaDomande.comment')} {row.commento}
                                                                        </Typography>
                                                                    </Box>
                                                                </Collapse>
                                                            </Grid>
                                                        }
                                                        {((!azienda || dettagliConferenza.data().approvazione) && !tablet) &&
                                                            <Comandi rifiutaDomanda={rifiutaDomanda} azienda={azienda}
                                                                     updatePosition={updatePosition}
                                                                     dettagliConferenza={dettagliConferenza} tipo={tipo}
                                                                     row={row} approvaDomanda={approvaDomanda}
                                                                     spostaDaApprovare={spostaDaApprovare}/>}
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>}
        </TableContainer>
    );
}
